.input-field, .dropdown {
  border-radius: 4px 4px 0 0;
  display: flex;
  height: 50px;
  margin: 0 0 20px;
  position: relative;
}
.input-field .error, .dropdown .error {
  color: #db162f;
}

.input-field__input, .dropdown__select {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: inherit;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  line-height: 1;
  padding: 14px 14px 10px 18px;
  width: 100%;
}

.input-field .notched-outline, .dropdown .notched-outline {
  color: inherit;
  display: flex;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  max-width: 100%;
  pointer-events: none;
  text-align: left;
  width: 100%;
}
.input-field .notched-outline .notched-outline__leading, .dropdown .notched-outline .notched-outline__leading,
.input-field .notched-outline .notched-outline__notch,
.dropdown .notched-outline .notched-outline__notch,
.input-field .notched-outline .notched-outline__trailing,
.dropdown .notched-outline .notched-outline__trailing {
  border-color: inherit;
  border-style: solid;
  border-width: 2px;
  height: 100%;
  pointer-events: none;
}
.input-field .notched-outline .notched-outline__leading, .dropdown .notched-outline .notched-outline__leading {
  border-radius: 4px 0 0 4px;
  border-right: none;
  width: 12px;
}
.input-field .notched-outline .notched-outline__notch, .dropdown .notched-outline .notched-outline__notch {
  border-right: none;
  border-left: none;
  border-top: none;
  flex: 0 0 auto;
  max-width: calc(100% - 24px);
  padding: 0 4px 0;
  width: auto;
}
.input-field .notched-outline .notched-outline__notch.noLabel, .dropdown .notched-outline .notched-outline__notch.noLabel {
  padding: 0;
}
.input-field .notched-outline .notched-outline__trailing, .dropdown .notched-outline .notched-outline__trailing {
  border-radius: 0 4px 4px 0;
  border-left: none;
  flex-grow: 1;
}

.input-field .floating-label, .dropdown .floating-label {
  cursor: text;
  color: inherit;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.15rem;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  top: 15px;
  bottom: auto;
  right: initial;
  max-width: 100%;
  max-width: 133.3333333333%;
  text-decoration: inherit;
  text-transform: inherit;
  transform-origin: left top;
  transform: translateY(-130%) scale(1);
  text-align: left;
  text-overflow: ellipsis;
  text-overflow: clip;
  white-space: nowrap;
}

#newTabsPopup .popup__header {
  text-align: center;
  font-size: x-large;
}
#newTabsPopup .message {
  margin: 0 0 10px;
  text-align: center;
}

@font-face {
  font-family: "Rubik";
  src: url("../assets/fonts/Rubik-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("../assets/fonts/Rubik-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("../assets/fonts/Rubik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("../assets/fonts/Rubik-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Rubik";
  src: url("../assets/fonts/Rubik-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Dancing Script";
  src: url("../assets/fonts/DancingScript.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
body {
  font-family: "Rubik";
}

h1 span,
h2 span,
h3 span,
h4 span {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
}

h1 {
  font-size: 1.5rem;
  font-weight: 500;
}

h2 {
  font-size: 1.25rem;
  font-weight: 500;
}

h3 {
  font-size: 1.125rem;
  font-weight: 500;
}

h4 {
  font-size: 1rem;
  font-weight: 500;
}

p {
  font-size: 1rem;
  font-weight: 400;
}

.font-mediumEmphasis {
  color: rgba(0, 0, 0, 0.6);
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  line-height: 1.15;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

article,
aside,
footer,
header,
main,
nav,
section {
  display: block;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  border: 0;
  border-style: none;
  display: inline-block;
  height: auto;
}

embed,
iframe,
img,
object,
video {
  max-width: 100%;
}

input,
textarea,
select,
option,
button {
  border: 0;
  border-radius: 0;
  outline: none;
}
input:hover,
textarea:hover,
select:hover,
option:hover,
button:hover {
  outline: none;
}
input:focus,
textarea:focus,
select:focus,
option:focus,
button:focus {
  outline: none;
}
input:active,
textarea:active,
select:active,
option:active,
button:active {
  outline: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
select {
  text-transform: none;
}

select {
  -webkit-appearance: none;
}

button {
  background: none;
  padding: 0;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* The main scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

/* The background of the draggable scrolling handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* On hover, make the scrolling handle lighter */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* The scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body {
  -webkit-overflow-scrolling: touch !important;
  background: rgb(250, 250, 250);
  color: rgb(39, 39, 39);
  overflow: auto;
}

textarea {
  resize: none;
}

svg .fill-path {
  fill: currentColor;
}

.freezeScroll {
  overflow: hidden;
}

.overlay {
  background: rgb(54, 106, 152);
  display: none;
  height: 100%;
  opacity: 0.9;
  position: fixed;
  top: 0;
  width: 100%;
}
.overlay.visible {
  display: block;
}
.overlay.modal {
  display: block;
  z-index: 100;
  background: transparent;
}

.error {
  color: #db162f;
}

.warning {
  color: #ffc857;
}

.customLink {
  cursor: pointer;
}

.ellipsis {
  border-radius: 4px;
  background: rgb(211, 211, 211);
  font-weight: 700;
  padding: 0 5px;
  margin: 0 0 0 4px;
}

.landingMessage {
  text-align: center;
  margin-top: 20px;
}

.disabledModule {
  /*display:none;*/
  opacity: 0.5;
  pointer-events: none;
}

.bold {
  font-weight: 500;
}

.filteredByData {
  border-bottom: 2px solid rgb(129, 185, 65);
  display: flex;
  flex-flow: row wrap;
  padding: 0 0 5px 0;
  position: relative;
  margin-top: 15px;
  margin-bottom: 10px;
}
.filteredByData::before {
  content: "Filtered By:";
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  top: -15px;
}
.filteredByData p {
  color: rgb(54, 106, 152);
  font-weight: 500;
  padding: 0 6px 0 0;
}
.filteredByData span {
  color: black;
}

.disabled {
  color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: none;
}

.noPointerEvents {
  pointer-events: none;
}

.heading {
  color: rgb(54, 106, 152);
  font-weight: 500;
}

.grid-container {
  height: calc(100% - 70px);
  min-height: 100vh;
  margin: 0 0 70px;
  transition: all 500ms;
  left: 0;
}
.grid-container.toggleClosed {
  width: calc(100% - 75px);
  left: 75px;
}

.actioncenter {
  margin: 0 auto;
  position: relative;
  padding: 10px 0 20px;
  width: 96%;
}
.actioncenter[data-active-module=plan] {
  padding: 0;
  width: 100%;
}

@media screen and (min-width: 900px) {
  .grid-container {
    position: relative;
    left: 200px;
    width: calc(100% - 200px);
    margin: 0;
  }
}
.btn,
.btn-icon {
  background-image: none;
  border-width: 2px;
  border-radius: 4px;
  border-style: solid;
  cursor: pointer;
  color: inherit;
  font-size: 0.875rem;
  font-weight: 400;
  height: auto;
  letter-spacing: 0.5px;
  line-height: normal;
  margin: 0 0 20px;
  padding: 10px 16px;
  text-transform: uppercase;
  width: auto;
}
.btn *,
.btn-icon * {
  pointer-events: none;
}
.btn--primary,
.btn-icon--primary {
  background: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
}
.btn--primary.btn--contained,
.btn-icon--primary.btn--contained {
  color: rgb(54, 106, 152);
}
.btn--primary.btn--outlined, .btn--primary.btn--text,
.btn-icon--primary.btn--outlined,
.btn-icon--primary.btn--text {
  color: rgb(255, 255, 255);
}
.btn--secondary,
.btn-icon--secondary {
  background: rgb(54, 106, 152);
  border-color: rgb(54, 106, 152);
}
.btn--secondary.btn--contained,
.btn-icon--secondary.btn--contained {
  color: rgb(255, 255, 255);
}
.btn--secondary.btn--outlined, .btn--secondary.btn--text,
.btn-icon--secondary.btn--outlined,
.btn-icon--secondary.btn--text {
  color: rgb(54, 106, 152);
}
.btn--danger,
.btn-icon--danger {
  background: red;
  border-color: red;
}
.btn--danger.btn--contained,
.btn-icon--danger.btn--contained {
  color: rgb(255, 255, 255);
}
.btn--danger.btn--outlined, .btn--danger.btn--text,
.btn-icon--danger.btn--outlined,
.btn-icon--danger.btn--text {
  color: red;
}
.btn.btn--contained,
.btn-icon.btn--contained {
  border: none;
  font-weight: 500;
}
.btn.btn--outlined,
.btn-icon.btn--outlined {
  background: none;
  font-weight: 500;
}
.btn.btn--text,
.btn-icon.btn--text {
  background: none;
  border: none;
  font-size: 1rem;
  font-weight: 400;
  padding: 0;
}

.btn {
  display: block;
  text-align: center;
}

.btn-icon {
  align-items: center;
  display: flex;
  justify-content: center;
}
.btn-icon svg {
  min-width: 26px;
  margin: 0 4px 0 0;
  width: 26px;
}
.btn-icon span {
  color: inherit;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  line-height: 26px;
  position: relative;
  top: 2px;
}
.btn-icon.icon-right svg {
  order: 1;
}
.btn-icon.icon-bottom {
  flex-flow: column;
}
.btn-icon.icon-bottom svg {
  margin: 0 0 4px;
}
.btn-icon.icon-bottom span {
  line-height: 1.5;
}

.btnWrap {
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 10px;
}
.btnWrap button {
  flex-grow: 1;
  margin: 0 1% 0 0;
}
.btnWrap button:last-child() {
  margin: 0;
}

.dropdownWrap {
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 0;
}
.dropdownWrap button {
  flex-grow: 1;
  margin: 0 1% 0 0;
}
.dropdownWrap button:last-child() {
  margin: 0;
}

.btn.disabled,
.btn-icon.disabled {
  background: rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
}
.btn.disabled.btn--text,
.btn-icon.disabled.btn--text {
  background: none;
}

.btn.error,
.btn-icon.error {
  background: #db162f;
}

.btn.attention,
.btn-icon.attention {
  background: rgb(129, 185, 65);
}

.btn.hidden,
.btn-icon.hidden {
  display: none;
}

.btn[data-toggled=true],
.btn-icon[data-toggled=true] {
  background: rgb(129, 185, 65);
}

button.moduleFilterBtn {
  width: 100%;
}

button.floatingActionBtn {
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
  margin: 0;
  min-width: auto;
  padding: 2px;
  position: fixed;
  bottom: 20px;
  right: 24px;
  height: 64px;
  width: 64px;
}
button.floatingActionBtn svg {
  margin: 0;
  width: 90%;
}

.dropdown svg {
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  width: 40px;
}

.dropdown__select:focus {
  background-color: #E8E8E8;
}

.dropdown--primary {
  color: rgb(255, 255, 255);
}
.dropdown--primary select {
  background: rgb(54, 106, 152);
}

.dropdown--secondary {
  color: rgb(39, 39, 39);
}

.dropdown.disabled {
  color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}
.dropdown.disabled.dropdown--primary {
  color: rgba(255, 255, 255, 0.5);
}

.dropdown.error {
  color: #db162f;
}
.dropdown.error .dropdown__select {
  color: rgb(39, 39, 39);
}

.dropdown.errorPopup {
  color: #db162f;
}
.dropdown.errorPopup .dropdown__select {
  color: rgb(39, 39, 39);
}

.dropdown.hidden {
  display: none;
}

.inlineDropdown {
  border-bottom: 2px solid rgb(39, 39, 39);
  position: relative;
  background: #fafafa;
}
.inlineDropdown__select {
  background: inherit;
  color: rgb(54, 106, 152);
  font-weight: 500;
  font-style: italic;
}
.inlineDropdown__select option {
  color: rgb(39, 39, 39);
  font-style: normal;
}
.inlineDropdown svg {
  pointer-events: none;
  width: 14px;
  height: auto;
  position: absolute;
  right: 4px;
}

.inlineDropdown.disabled {
  color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}
.inlineDropdown.disabled select {
  color: inherit;
}

.inlineDropdown.error {
  color: #db162f;
}
.inlineDropdown.error .inlineDropdown__select {
  color: rgb(39, 39, 39);
}

.input-field.textarea {
  height: 100px;
}
.input-field__input:focus {
  background-color: #e8e8e8;
}

.input-field--primary {
  color: rgb(255, 255, 255);
}
.input-field--primary--focused {
  color: rgb(255, 255, 255);
}

.inputCharCount {
  color: rgb(39, 39, 39);
  position: absolute;
  right: 5px;
  bottom: -15px;
}
.inputCharCount.overLimit {
  color: #db162f;
}

.checkAlign > span::after {
  left: 138px !important;
}

.sttInputIcon {
  position: absolute;
  right: -6px;
  top: -8px;
  width: 22px;
  margin: 0px;
  background: rgb(255, 255, 255) !important;
}
.sttInputIcon svg {
  margin: 0px;
}
.sttInputIcon.stt_listening {
  fill: #db162f;
}

.eyeInputIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 22px !important;
  margin: 0px;
  transition: 0.2s;
}
.eyeInputIcon svg {
  margin: 0px;
}

.input-field--secondary {
  color: rgb(39, 39, 39);
}
.input-field--secondary--focused {
  color: rgb(54, 106, 152);
}

.input-field.error {
  color: #db162f !important;
}
.input-field.error .notched-outline {
  color: #db162f !important;
  border-color: #db162f !important;
}
.input-field.error .notched-outline__leading,
.input-field.error .notched-outline__notch,
.input-field.error .notched-outline__trailing {
  border-color: #db162f !important;
}
.input-field.error .input-field__input {
  color: rgb(39, 39, 39) !important;
}

.input-field.errorPopup {
  color: #db162f !important;
}
.input-field.errorPopup .notched-outline {
  color: #db162f !important;
  border-color: #db162f !important;
}
.input-field.errorPopup .notched-outline__leading,
.input-field.errorPopup .notched-outline__notch,
.input-field.errorPopup .notched-outline__trailing {
  border-color: #db162f !important;
}
.input-field.errorPopup .input-field__input {
  color: rgb(39, 39, 39) !important;
}

.input-field.warning {
  color: #ffc857;
}

.input-field.hidden {
  display: none;
}

.input-field.disabled {
  color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: none;
}

.checkbox.input-field {
  margin-right: 8px;
}
.checkbox.disabled {
  opacity: 0.5;
}
.checkbox .input-field__input {
  appearance: auto;
  width: 20px;
}

.checkboxGroup {
  display: flex;
  flex-flow: row wrap;
}
.checkboxGroup .checkbox-mtd {
  margin: 0 20px 0 0;
}

.checkbox-mtd {
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  z-index: 0;
}

.checkbox-mtd > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  box-shadow: none;
  display: block;
  height: 40px;
  position: absolute;
  left: -10px;
  top: -8px;
  margin: 0;
  outline: none;
  opacity: 0;
  pointer-events: none;
  transform: scale(1);
  transition: opacity 0.3s, transform 0.2s;
  width: 40px;
  z-index: -1;
}

.checkbox-mtd > span {
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  width: 100%;
}

.checkbox-mtd::before {
  content: "";
  box-sizing: border-box;
  border: solid 2px;
  border-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  display: inline-block;
  height: 20px;
  margin: 0 6px 0 0;
  transition: border-color 0.2s, background-color 0.2s;
  vertical-align: top;
  min-width: 20px;
}

.checkbox-mtd > span::after {
  content: "";
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  display: block;
  height: 5px;
  position: absolute;
  top: 4px;
  left: 1px;
  transform: translate(3px, 4px) rotate(-45deg);
  width: 10px;
}

.checkbox-mtd > input:checked {
  background-color: rgb(33, 150, 243);
}

.checkbox-mtd > input:checked + span::after {
  border-color: rgb(54, 106, 152);
}

.checkbox-mtd > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.checkbox-mtd > input:checked:active + span::before {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.6);
}

.checkbox-mtd.error::before {
  border: 2px solid #db162f;
}

.checkbox-mtd.redButNotError::before {
  border: 2px solid #db162f;
}

.checkbox-mtd.redButNotError > span {
  color: #db162f;
}

.checkbox-mtd.disabled > input {
  pointer-events: none;
}

.input-field.textarea {
  height: auto !important;
}

textarea.autosize {
  height: auto;
  min-height: 100px !important;
}

input[type=time]::-webkit-clear-button {
  display: none;
}

input[type=time]::-ms-clear {
  display: none;
}

input[type=radio i]:focus {
  outline: 1px solid #0075ff;
}

.site-header {
  background: rgb(255, 255, 255);
  border-bottom: 6px solid rgb(129, 185, 65);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 10px;
}
.site-header.fadeOut {
  opacity: 0.4;
}

.loginInfo {
  display: flex;
  flex-flow: row wrap;
}
.loginInfo p:first-child {
  width: 100%;
  margin-bottom: 2px;
}
.loginInfo__firstName {
  font-weight: 500;
  margin-right: 4px;
}
.loginInfo__lastName {
  font-weight: 500;
}

.desktopUtilMenuBtn {
  color: rgb(39, 39, 39);
  position: relative;
  transition: all 200ms ease-out;
  width: 32px;
}
.desktopUtilMenuBtn.menu-visible {
  color: rgb(255, 255, 255);
}
@media screen and (max-width: 899px) {
  .desktopUtilMenuBtn {
    display: none;
  }
}

.mobileActive .desktopUtilMenuBtn {
  display: none;
}

.form {
  margin: 0 0 20px;
}
.form > *:last-child {
  margin-bottom: 0;
}

.popup {
  border-radius: 4px;
  background: rgb(250, 250, 250);
  max-height: calc(100vh - 100px);
  max-width: 400px;
  overflow-y: auto;
  padding: 40px 12px 16px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 96%;
  z-index: 101;
}
.popup *:not(html):not(svg) {
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
.popup--static {
  display: none;
}
.popup--static.visible {
  display: block;
}
.popup--filter button {
  padding: 14px 16px;
  width: 100%;
}
.popup__header {
  font-weight: 500;
  margin: 0 0 10px;
}
.popup .dropdown,
.popup .input-field {
  margin-top: 8px;
}
.popup .closePopupBtn {
  width: auto;
  position: absolute;
  top: 8px;
  right: 8px;
}

#formPopup.popup {
  max-width: 100%;
  width: auto;
}

.popup.warning {
  background: #ffc857;
}
.popup.warning p {
  color: rgb(39, 39, 39);
  font-weight: 500;
  text-align: center;
  padding-bottom: 10px;
}
.popup.error {
  background: #db162f;
}
.popup.error p {
  color: rgb(255, 255, 255);
  font-weight: 500;
}
.popup.disabled * {
  pointer-events: none;
}

.mobileActive .popup {
  max-height: 360px;
}
@media screen and (min-height: 560px) {
  .mobileActive .popup {
    max-height: 400px;
  }
}
@media screen and (min-height: 800px) {
  .mobileActive .popup {
    max-height: 600px;
  }
}

.password-error {
  color: red;
  border-color: red;
}

.successfulSavePopup, .failSavePopup {
  text-align: center;
}
.successfulSavePopup svg, .failSavePopup svg {
  color: rgb(129, 185, 65);
  margin: 0 0 20px;
  width: 100px;
}
.successfulSavePopup p, .failSavePopup p {
  font-size: 22px;
  font-weight: 500;
}

.failSavePopup > svg {
  color: #db162f;
}

.pendingSavePopup {
  text-align: center;
  overflow: hidden;
  padding-top: 0px;
}
.pendingSavePopup .spinner {
  margin-bottom: 20px;
}
.pendingSavePopup .spinner .spinner__message {
  display: block;
}
.pendingSavePopup svg {
  color: rgb(129, 185, 65);
  margin: 0 0 20px;
  width: 100px;
}
.pendingSavePopup p {
  font-size: 22px;
  font-weight: 500;
}

#formPopup {
  max-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  z-index: 9999999999;
}
#formPopup #viewer {
  max-height: 100%;
  height: auto;
  max-width: 100%;
  overflow-y: auto;
}

.popup__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

#infalFormHolder {
  display: none;
}

#change {
  display: none;
}

#reset {
  display: none;
}

.anywhere-logo {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 226px;
}

main {
  background: rgb(54, 106, 152);
  padding: 20px 0 40px;
}

.login-forms {
  background: rgb(250, 250, 250);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 0 auto;
  max-width: 420px;
  padding: 20px 0 30px;
  width: 92%;
}
.login-forms--inner {
  margin: 0 auto;
  max-width: 340px;
  width: 90%;
}

.custom-logo {
  text-align: center;
  margin: 0 0 1.5rem;
}

#customLoginText {
  color: rgb(39, 39, 39);
  margin: 0 0 20px;
}

form {
  width: 100%;
}

#passwordToggle1,
#passwordToggle2,
#passwordToggle3,
#passwordToggle4 {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 20px;
}

#loginPopup p {
  font-weight: 500;
  margin: 0 0 20px;
}

.btn-login {
  background: rgb(129, 185, 65);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: none;
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  font-weight: 500;
  padding: 15px 0;
  width: 100%;
}

.confirmMessage {
  border: 3px solid;
  border-color: #ffc857;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 20px;
  padding: 0 5px 5px;
}

.password-warning {
  color: rgb(39, 39, 39);
  border-color: #ffc857;
}

.password-error {
  color: #db162f;
  border-color: #db162f;
}

.password-help {
  color: rgb(39, 39, 39);
  margin: 0 0 20px;
}

.password-help * {
  color: inherit;
  font-weight: 500;
}

.password-help span {
  position: relative;
  top: 2px;
}

.password-help a {
  cursor: pointer;
  font-variant: small-caps;
  font-size: 0.875rem;
}
@media screen and (min-width: 375px) {
  .password-help a {
    font-size: 1rem;
  }
}

.backToLogin {
  align-items: center;
  color: rgb(39, 39, 39);
  display: none;
  font-weight: 500;
}
.backToLogin svg {
  margin: 0 6px 0 0;
  pointer-events: none;
  width: 24px;
}
.backToLogin span {
  color: inherit;
  letter-spacing: 0.2px;
  line-height: 1;
  order: 1;
}

.error {
  background-color: #db162f;
  border-radius: 10px;
  display: none;
  padding: 10px;
  z-index: 1;
}
.error__inner {
  align-items: center;
  display: flex;
}
.error svg {
  fill: rgb(255, 255, 255);
  margin: 0 10px 0 0;
  min-width: 24px;
}
.error__text {
  color: rgb(255, 255, 255);
}
.error#mfaErrorBox {
  text-align: center;
}

.versioning {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
}

.vertext {
  font-weight: 600;
  margin: 0 5px 0 0;
  text-transform: capitalize;
}